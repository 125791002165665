import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/cpes/perfiles-velocidad`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(perfil) {
    return apiClient.post(`${perfil.id}`, perfil)
  },

  eliminar(idPerfil) {
    return apiClient.delete(`${idPerfil}`)
  },

  guardar(perfil) {
    return apiClient.post('', perfil)
  },

  perfilJSON(idPerfil) {
    return apiClient.get(`${idPerfil}.json`)
  },

  perfilesJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/cpes/perfiles-velocidad.json`, {
      params: params
    })
  }
}
