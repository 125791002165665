import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/cpes/estados`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  estadosJSON() {
    return apiClient.get(`${API}/cpes/estados.json`)
  }
}
