import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/cpes/modelos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(modelo) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      // Carga del FormData
      Object.keys(modelo).map(function(key) {
        data.append(key, modelo[key])
      })

      var imagen = document.getElementsByName("foto")

      if (imagen.length > 0) data.append('foto', imagen[0].files[0])

      if (modelo._eliminarFotoAntigua) data.append('eliminar_foto_antigua', true)

      var req = new XMLHttpRequest()
      req.open(
        'POST',
        API +
          '/cpes/modelos/' +
          modelo.id +
          '?_tk=' +
          localStorage.getItem('argusblack.token'),
        true
      )

      req.onreadystatechange = function() {
        if (req.readyState == 4) {
          if (req.status == 200) {
            resolve({
              data: req.responseText
            })
          } else {
            reject({
              response: {
                status: req.status,
                data: req.responseText
              }
            })
          }
        }
      }

      req.send(data)
    })
  },

  eliminar(idModelo) {
    return apiClient.delete(`${idModelo}`)
  },

  guardar(modelo) {
    return new Promise((resolve, reject) => {
      var data = new FormData()

      // Carga del FormData
      Object.keys(modelo).map(function(key) {
        data.append(key, modelo[key])
      })

      var imagen = document.getElementsByName("foto")

      if (imagen.length > 0) data.append('foto', imagen[0].files[0])

      var req = new XMLHttpRequest()
      req.open(
        'POST',
        API + '/cpes/modelos?_tk=' + localStorage.getItem('argusblack.token'),
        true
      )

      req.onreadystatechange = function() {
        if (req.readyState == 4) {
          if (req.status == 200) {
            resolve({
              data: req.responseText
            })
          } else {
            reject({
              response: {
                status: req.status,
                data: req.responseText
              }
            })
          }
        }
      }

      req.send(data)
    })
  },

  modeloJSON(idModelo) {
    return apiClient.get(`${idModelo}.json`)
  },

  modelosJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/cpes/modelos.json`, { params: params })
  }
}
