<template>
  <layout :tituloPagina="`Network | CPEs | ${modo == 'nuevo' ? 'Nuevo' : 'Edición'}`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title text-truncate w-100">
          {{ modo == 'nuevo' ? 'Nuevo' : 'Edición de ' }} CPE
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <!-- Columna1 -->
          <div class="col-md-6 b-r">
            <div class="row">
              <div class="col-md-3 text-center">
                <div class="imgCpe">
                  <img
                    :src="`${API}/cpes/modelos/${cpe.id_modelo}/imagen?tipo_foto=128x128&_tk=${tk}`"
                    alt="imagen del cpe"
                    v-if="cpe.id_modelo != null"
                    class="img-thumbnail"
                  />
                </div>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6">
                    <label class="text-truncate w-100">Nombre del CPE</label>
                    <input
                      v-model="cpe.nombre"
                      ref="nombre"
                      class="form-control"
                      placeholder="Nombre del CPE"
                      name="nombre"
                    />
                  </div>

                  <div class="col-md-6">
                    <label class="text-truncate w-100">Estado</label>
                    <select
                      v-model="cpe.id_estado"
                      name="id_estado"
                      class="form-select"
                    >
                      <option
                        v-for="estado in estados"
                        :value="estado.id"
                        :key="estado.id"
                      >
                        {{ estado.nombre }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label class="text-truncate w-100">Marca</label>
                    <select
                      v-model="cpe.id_marca"
                      @change="
                        cpe.id_modelo = null,
                        cargarModelos()
                      "
                      ref="id_marca"
                      class="form-select"
                    >
                      <option :value="null">Seleccionar</option>
                      <option
                        v-for="marca in marcas"
                        :value="marca.id"
                        :key="marca.id"
                      >
                        {{ marca.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="text-truncate w-100">Modelo</label>
                    <select
                      v-model="cpe.id_modelo"
                      ref="id_modelo"
                      class="form-select"
                      :disabled="modelos.length == 0"
                    >
                      <option :value="null">Seleccionar</option>
                      <option
                        v-for="modelo in modelos"
                        :value="modelo.id"
                        :key="modelo.id"
                      >
                        {{ modelo.identificador }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />
            <h4 class="text-truncate w-100">Credencial para administración del CPE</h4>

            <div class="row">
              <div class="col-md-6">
                <label class="text-truncate w-100">Usuario</label>
                <div class="input-group">
                  <input
                    v-model="cpe.usuario"
                    class="form-control"
                    placeholder="Usuarios del CPE"
                    name="usuario"
                    :readonly="cpe.usuario_bloqueado"
                  />
                  <button
                    class="btn btn-primary"
                    @click="cpe.usuario_bloqueado = !cpe.usuario_bloqueado"
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-lock': cpe.usuario_bloqueado,
                        'fa-unlock': !cpe.usuario_bloqueado
                      }"
                    ></i>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <label class="text-truncate w-100">Clave</label>
                <div class="input-group">
                  <input
                    v-model="cpe.clave"
                    type="text"
                    class="form-control"
                    placeholder="Clave del CPE"
                    name="clave"
                    :readonly="cpe.clave_bloqueada"
                  />
                  <button
                    class="btn btn-primary"
                    @click="cpe.clave_bloqueada = !cpe.clave_bloqueada"
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-lock': cpe.clave_bloqueada,
                        'fa-unlock': !cpe.clave_bloqueada
                      }"
                    ></i>
                  </button>
                </div>
              </div>
            </div>

            <br /><br />
            <div class="row">
              <div class="col-md-12">
                <label class="text-truncate w-100">Nota de información complementaria para el CPE</label>
                <textarea
                  v-model="cpe.nota"
                  class="form-control"
                  name="nota"
                  rows="5"
                  maxlength="2000"
                  placeholder="Nota complementaria del CPE"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- Fin de columna1 -->

          <!-- Columna2 -->
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <label class="text-truncate w-100">Modo de administración</label>
              </div>
              <div class="col-md-6">
                <select
                  class="form-select"
                  v-model="cpe.pppoe"
                  @change="
                    cargarServiciosPPPoE(),
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                >
                  <option value="0">Por dirección IP estática</option>
                  <option value="1">Por credencial PPPoE</option>
                </select>
              </div>
            </div>
            <br />

            <div v-show="cpe.pppoe == true">
              <h4 class="text-truncate w-100">Credencial PPPoE</h4>
              <div class="row">
                <div class="col-md-6">
                  <label class="text-truncate w-100">Usuario</label> <br />
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="cpe.usuario_pppoe"
                      ref="usuario_pppoe"
                      placeholder="Usuario PPPoE"
                      :readonly="cpe.usuario_pppoe_bloqueado"
                    />
                    <button
                      class="btn btn-primary"
                      @click="generarUsuarioPPPoE()"
                      :disabled="cpe.usuario_pppoe_bloqueado"
                      v-show="
                        cpe.usuario_pppoe == '' || cpe.usuario_pppoe == null
                      "
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <button
                      class="btn btn-primary"
                      @click="
                        cpe.usuario_pppoe_bloqueado = !cpe.usuario_pppoe_bloqueado
                      "
                    >
                      <i
                        class="fa"
                        :class="{
                          'fa-lock': cpe.usuario_pppoe_bloqueado,
                          'fa-unlock': !cpe.usuario_pppoe_bloqueado
                        }"
                      ></i>
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="text-truncate w-100">Clave</label> <br />
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="cpe.clave_pppoe"
                      ref="clave_pppoe"
                      placeholder="Clave PPPoE"
                      :readonly="cpe.clave_pppoe_bloqueada"
                    />
                    <button
                      class="btn btn-primary"
                      @click="generarClavePPPoE()"
                      v-show="
                        cpe.clave_pppoe == '' || cpe.clave_pppoe == null
                      "
                      :disabled="cpe.clave_pppoe_bloqueada"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    
                    <button
                      class="btn btn-primary"
                      @click="
                        cpe.clave_pppoe_bloqueada = !cpe.clave_pppoe_bloqueada
                      "
                    >
                      <i
                        class="fa"
                        :class="{
                          'fa-lock': cpe.clave_pppoe_bloqueada,
                          'fa-unlock': !cpe.clave_pppoe_bloqueada
                        }"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
              <br /><br />
            </div>

            <h4 class="text-truncate w-100">Del CPE</h4>
            <div class="row">
              <div class="col-md-6">
                <label class="text-truncate w-100">Dirección IP del puerto WAN</label>
                <input
                  v-model="cpe.ip_wan"
                  class="form-control"
                  name="ip_wan"
                  @change="
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                  placeholder="172.16.1.X"
                  ref="ip_wan"
                  v-show="cpe.pppoe == false"
                />
                <input
                  type="text"
                  class="form-control"
                  value="- - Automática - -"
                  readonly
                  v-show="cpe.pppoe == true"
                />
              </div>
              <div class="col-md-6">
                <label class="text-truncate w-100">MAC del puerto WAN</label>
                <input
                  v-model="cpe.mac_wan"
                  class="form-control"
                  ref="mac_wan"
                  maxlength="17"
                  placeholder="01:02:03:A4:B5:C6"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="text-truncate w-100">Dirección IP del puerto LAN</label>
                <input
                  v-model="cpe.ip_lan"
                  class="form-control"
                  name="ip_lan"
                  placeholder="192.168.100.1"
                  ref="ip_lan"
                />
              </div>
              <div class="col-md-6">
                <label class="text-truncate w-100">MAC del puerto LAN</label>
                <input
                  v-model="cpe.mac_lan"
                  class="form-control"
                  ref="mac_lan"
                  maxlength="17"
                  placeholder="01:02:03:A4:B5:D6"
                />
              </div>
            </div>
            <br>
            <!-- <h4 class="text-truncate w-100">
              Registrar historial de tráfico
            </h4>
            <div class="row">
              <div class="col-md-6">
                <div class="form-check-success form-check-switch-right form-switch form-switch-lg">
                  <input
                    :checked="cpe.ht"
                    @change="cpe.ht = !cpe.ht"
                    class="form-check-input"
                    type="checkbox"
                    id="historialTrafico"
                  />
                  <label class="form-check-label" for="historialTrafico">
                    Historial de tráfico del CPE
                  </label>
                </div>
              </div>
            </div> -->
            <br /><br />
            <div class="text-right">
              Forzar sincronización de la configuración en el router
              <button class="btn btn-outline-dark btn-sm" @click="resinc()">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <!-- Fin de columna2 -->
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-6">
        <!-- Inicio card -->
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Router de administración donde será registrado el CPE
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label class="text-truncate w-100">Router administrador</label>
                <select
                  class="form-select"
                  v-model="cpe.id_router"
                  name="id_router"
                  @change="
                    refrescarProveedoresInternet(),
                    cargarServiciosPPPoE(),
                    cargarPuertasEnlace(function() {
                      actualizarConfiguracionIpParaCpe()
                    })
                  "
                >
                  <option :value="null">Ninguno</option>
                  <option
                    v-for="router in routers"
                    :value="router.id"
                    :key="router.id"
                  >
                    {{ router.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6">
                <label class="text-truncate w-100">Proveedor de internet</label>
                <select
                  class="form-select"
                  name="id_isp"
                  v-model="cpe.id_isp"
                  ref="id_isp"
                >
                  <option v-for="isp in isps" :value="isp.id" :key="isp.id">
                    {{ isp.nombre }}
                  </option>
                </select>
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-md-6">
                <label class="text-truncate w-100">Conectado a sitio</label>
                <select
                  class="form-select"
                  v-model="cpe.id_sitio"
                  name="id_sitio"
                >
                  <option :value="null">Sitio del router</option>
                  <option
                    v-for="sitio in sitios"
                    :key="sitio.id"
                    :value="sitio.id"
                  >
                    {{ sitio.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 mt-4">
                <div class="d-grid gap-2">
                  <button class="btn btn-outline-primary btn-block" @click="mostrarMdlThroughPut(cpe.id)" v-show="modo=='edicion'">
                    <i class="mdi mdi-chart-bell-curve-cumulative font-size-15"></i>
                    Throughput
                  </button>
                </div>
              </div>
            </div>

            <br />

            <div class="row" v-if="cpe.id_router != null">
              <div class="col-md-6">
                <h5 class="text-truncate w-100">Puertas de enlace disponibles</h5>
                <template v-for="(puerta, index) in puertas_enlace">
                  <span class="badge font-size-15 badge-pill badge-soft-primary" v-if="true" :key="index">
                    {{ puerta }}
                  </span>
                  &nbsp;
                </template>
                <span
                  v-if="puertas_enlace.length == 0"
                  class="badge font-size-15 bg-warning bg-gradient"
                >
                  Ninguna
                </span>
              </div>
              <div class="col-md-6">
                <h5 class="text-truncate w-100">Servicios PPPoE disponibles</h5>
                <template v-for="(servicio, index) in serviciosPPPoE">
                  <span class="badge font-size-15 badge-pill badge-soft-primary" v-if="true" :key="index">
                    {{ servicio.nombre }}
                  </span>
                  &nbsp;
                </template>
                <span
                  v-if="serviciosPPPoE.length == 0"
                  class="badge font-size-15 bg-warning bg-gradient"
                >
                  Ninguno
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Fin de card -->

        <!-- Inicio de card -->
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">Control del servicio de internet</h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-5">
                <select
                  class="form-select"
                  v-model="cpe.id_perfil_velocidad"
                  @change="cargarPerfilSeleccionado()"
                  ref="id_perfil_velocidad"
                >
                  <option
                    v-for="perfil in perfiles"
                    :value="perfil.id"
                    :key="perfil.id"
                  >
                    {{ perfil.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-7">
                <div
                  v-if="
                    perfilSeleccionado != null &&
                    perfilSeleccionado != undefined
                  "
                >
                  {{ perfilSeleccionado.carga }}
                  {{ perfilSeleccionado.unidad_carga }}
                  /
                  {{ perfilSeleccionado.descarga }}
                  {{ perfilSeleccionado.unidad_descarga }}
                </div>
              </div>
            </div>

            <template
              v-if="
                perfilEnContrato != null &&
                  cpe.id_perfil_velocidad !=
                    cpe.id_perfil_velocidad_en_contrato
              "
            >
              <br />
              <p>
                El perfil usado en el
                <router-link
                  v-if="cpe.id_cliente != null || cpe.id_contrato != null"
                  :to="{
                    name: 'edicionContrato',
                    params: {
                      id_cliente: cpe.id_cliente,
                      id_contrato: cpe.id_contrato
                    }
                  }"
                >
                  contrato
                </router-link>
                es <strong>{{ perfilEnContrato.nombre }}</strong>
              </p>

              <div
                class="alert alert-danger"
                v-if="
                  cpe.id_perfil_velocidad_en_contrato !=
                    cpe.id_perfil_velocidad
                "
              >
                El perfil de velocidad no coincide con el registrado en el
                <router-link
                  v-if="cpe.id_cliente != null || cpe.id_contrato != null"
                  :to="{
                    name: 'edicionContrato',
                    params: {
                      id_cliente: cpe.id_cliente,
                      id_contrato: cpe.id_contrato
                    }
                  }"
                >
                  contrato
                </router-link>
              </div>

              <p
                v-if="
                  cpe.id_perfil_velocidad_en_contrato !=
                    cpe.id_perfil_velocidad
                "
                class="text-justify"
              >
                Es posible asignar un
                <strong>perfil de velocidad</strong> diferente al registrado
                en el contrato, sin embargo, este método es usado solo para
                cuestiones de pruebas. Es recomendable asignar el perfil de
                velocidad correcto a través de la selección del
                <strong>Plan de servicio</strong>
                adecuado en el contrato y así evitar que el sistema realice
                una facturación incorrecta.
              </p>
            </template>

            <br />
            <div class="row">
              <div class="col-md-6">
                <label class="text-truncate w-100">Control automático</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="!cpe.control_manual"
                    @change="cpe.control_manual = !cpe.control_manual"
                    class="form-check-input"
                    type="checkbox"
                    id="controlAutomatico"
                  />
                  <label class="form-check-label" for="controlAutomatico"></label>
                </div>
              </div>

              <div class="col-md-6">
                <label class="text-truncate w-100">Acceso a internet</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="cpe.enlace_activo"
                    @change="cpe.enlace_activo = !cpe.enlace_activo"
                    class="form-check-input"
                    type="checkbox"
                    :disabled="!cpe.control_manual"
                    id="enlaceActivo"
                  />
                  <label class="form-check-label" for="enlaceActivo"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Fin de card -->
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Configuración recomendada para el CPE
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-show="cpe.pppoe == true">
              <h4 class="text-truncate w-100">Credencial PPPoE</h4>
              <div class="row">
                <label class="col-md-6">
                  Usuario
                </label>
                <div class="col-md-6">
                  <input
                    v-model="cpe.usuario_pppoe"
                    placeholder="Usuario PPPoE"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-md-6">
                  Clave
                </label>
                <div class="col-md-6">
                  <input
                    v-model="cpe.clave_pppoe"
                    placeholder="Clave PPPoE"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <br />
              <div class="row">
                <label class="col-md-6">
                  Servicios PPPoE disponibles en el router
                </label>
                <div class="col-md-6">
                  <input
                    type="text"
                    value="- - Ninguno disponible - -"
                    class="form-control"
                    readonly
                    v-if="serviciosPPPoE.length == 0"
                  />
                  <input
                    type="text"
                    v-for="servicio in serviciosPPPoE"
                    :key="servicio.id_lan"
                    v-model="servicio.nombre"
                    class="form-control"
                    readonly
                    v-show="serviciosPPPoE.length > 0"
                  />
                </div>
              </div>
            </div>
            <div v-show="cpe.pppoe == false">
              <div class="row">
                <label class="col-md-6">
                  Dirección IP
                  {{
                    cpe.pppoe == true ? ' en enlace PPPoE' : 'del puerto WAN'
                  }}
                </label>
                <div class="col-md-6">
                  <input
                    v-model="cpe.ip_wan"
                    class="form-control"
                    placeholder="172.16.1.X"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-md-6">
                  Mascara de subred
                </label>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="configuracionCpe.mascara_subred"
                    placeholder="- - Ninguna - -"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-md-6">
                  Puerta de enlace
                </label>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="configuracionCpe.puerta_enlace"
                    placeholder="- - Ninguna - -"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-md-6">
                  DNS 1
                </label>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="configuracionCpe.dns1"
                    placeholder="8.8.4.4"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-md-6">
                  DNS 2
                </label>
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="configuracionCpe.dns2"
                    placeholder="8.8.8.8"
                    readonly
                  />
                </div>
              </div>
            </div>

            <br />
            <div class="row">
              <label class="col-md-6">Dirección IP del puerto LAN</label>
              <div class="col-md-6">
                <input
                  v-model="cpe.ip_lan"
                  class="form-control"
                  placeholder="192.168.100.1"
                  readonly
                />
              </div>
            </div>
            <div class="row">
              <label class="col-md-6">NAT</label>
              <div class="col-md-6">
                <input
                  type="text"
                  value="Habilitar NAT en el CPE"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="cpe.id_cliente != null || cpe.id_contrato != null">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Contrato del CPE
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            Ver contrato:
            <router-link
              class="btn btn-sm btn-outline-success"
              v-if="cpe.id_cliente != null || cpe.id_contrato != null"
              :to="{
                name: 'edicionContrato',
                params: {
                  id_cliente: cpe.id_cliente,
                  id_contrato: cpe.id_contrato
                }
              }"
            >
              {{ cpe.id_contrato }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary" @click="cerrar()">
          <i class="mdi mdi-chevron-left"></i>
          Atrás
        </button>
        <button
          type="submit"
          class="btn btn-success"
          @click="modo == 'nuevo' ? guardar() : actualizar()"
        >
          <i class="mdi mdi-content-save"></i>
          {{ modo == 'nuevo' ? 'Guardar' : '' }}
          {{ modo == 'edicion' ? 'Actualizar' : '' }}
        </button>
      </div>
    </div>

    <br>



    <mdl-through-put ref="mdlthroughput" v-if="$store.state.todo.nombre_empresa != 'Livaur Internet 2'"></mdl-through-put>
    <mdl-through-put-nuevo ref="mdlthroughput" v-if="$store.state.todo.nombre_empresa == 'Livaur Internet 2'"></mdl-through-put-nuevo>



  </layout>
</template>

<script>
import Layout from "@/views/layouts/main"

import API from '@/API.js'
import CpeSrv from '@/services/CpeSrv.js'
import EstadoCpesSrv from '@/services/EstadoCpesSrv.js'
import MarcaCpesSrv from '@/services/MarcaCpesSrv.js'
import ModeloCpesSrv from '@/services/ModeloCpesSrv.js'
import PerfilVelocidadCpesSrv from '@/services/PerfilVelocidadCpesSrv.js'
import RouterSrv from '../../../services/RouterSrv'
import SistemaSrv from '../../../services/SistemaSrv'
import SitioSrv from '@/services/SitioSrv.js'


import MdlThroughPut from './MdlThroughput.vue'
import MdlThroughPutNuevo from './MdlThroughput-nuevo.vue'



var ipaddr = require('ipaddr.js')

export default {
  name: 'EdicionCpe',
  components: { Layout, MdlThroughPut, MdlThroughPutNuevo },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      baseUrl: window.location.origin,
      modo: 'nuevo',
      cpe: {
        id: null,
        id_estado: 1,
        id_router: null,
        id_isp: null,
        id_marca: null,
        id_modelo: null,
        id_sitio: null,
        nombre: '',
        usuario: '',
        clave: '',
        usuario_bloqueado: true,
        clave_bloqueada: true,
        control_manual: 0,
        enlace_activo: 0,
        ip_wan: '',
        mac_wan: '',
        ip_lan: '',
        mac_lan: '',
        pppoe: 0,
        usuario_pppoe: '',
        clave_pppoe: '',
        usuario_pppoe_bloqueado: true,
        clave_pppoe_bloqueada: true,
        ht: 0,
        ht_sinc: 1
      },
      cpeInicial: {},
      cpes: [],
      estados: [],
      isps: [],
      marcas: [],
      modelos: [],
      routers: [],
      perfiles: [],
      perfilEnContrato: null,
      perfilSeleccionado: null,
      serviciosPPPoE: [],
      puertas_enlace: '',
      sitios: [],

      /**
       * configuracionCpe es aquella sugerida al usuario administrador, para que pueda
       * configurar su CPE físicamente
       */
      configuracionCpe: {
        puerta_enlace: '',
        mascara_subred: '',
        cidr: null,
        dns1: '',
        dns2: ''
      }
    }
  },
  watch: {
    'cpe.ht': function(newVar, oldVar) {
      this.cpe.ht_sinc = false
    }
  },
  created: function() {
    var self = this

    // Registro de una copia del CPE inicial
    self.cpeInicial = Object.assign({}, this.cpe)

    if (this.$route.query.id_router) {
      this.cpe.id_router = this.$route.query.id_router
      self.refrescarProveedoresInternet()
      self.cargarServiciosPPPoE()
    }

    if (this.$route.query.ip_disponible) {
      this.cpe.ip_wan = this.$route.query.ip_disponible
      self.cargarPuertasEnlace(function() {
        self.actualizarConfiguracionIpParaCpe()
      })
    }

    if (this.$route.path.indexOf('nuevo') == -1) self.modo = 'edicion'

    // Cargas de sitios
    self.cargarSitios()
    // Cargas iniciales
    switch (self.modo) {
      case 'nuevo':
        self.cargarEstados()
        self.cargarMarcas()
        self.cargarRouters()
        self.cargarPerfilesVelocidad()
        Object.assign(self.cpe, {
          usuario_bloqueado: false,
          clave_bloqueada: false,
          usuario_pppoe_bloqueado: false,
          clave_pppoe_bloqueada: false
        })
        break
      case 'edicion':
        self.cargarEstados(function() {
          self.cargarCpe(function() {
            self.cargarMarcas(function() {
              self.cargarModelos()
            })
            self.cargarRouters(function() {
              self.refrescarProveedoresInternet()
            })
            self.cargarPerfilesVelocidad(function() {
              self.cargarPerfilSeleccionado()
            })

            if (self.cpe.id_contrato != null) {
              self.cargarPerfilVelocidadContrato()
            }

            if (self.cpe.id_router != null) {
              self.cargarServiciosPPPoE()
              self.cargarPuertasEnlace(function() {
                self.actualizarConfiguracionIpParaCpe()
              })
            }
          })
        })
        break
    }
  },
  methods: {
    actualizar: function() {
      var self = this
      var cpe = Object.assign({}, self.cpe)

      if (cpe.pppoe == true) {
        if (cpe.usuario_pppoe == null || cpe.usuario_pppoe == '') {
          iu.msg.warning('Es necesario un usuario para la credencial PPPoE')
          self.$refs.usuario_pppoe.focus()
          return
        }
        if (cpe.clave_pppoe == null || cpe.clave_pppoe == '') {
          iu.msg.warning('Es necesario una clave para la credencial PPPoE')
          self.$refs.clave_pppoe.focus()
          return
        }
      }

      if (!self.datosCorrectos()) return

      CpeSrv.actualizar(self.cpe)
        .then(response => {
          iu.msg.success('Actualización correcta')
        })
        .catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo actualizar'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
    },

    actualizarConfiguracionIpParaCpe: function() {
      var self = this,
        ipcpe = self.cpe.ip_wan

      // Si tiene la bandera PPPoE activada se evita continuar
      if (self.cpe.pppoe == true) return

      // Si el CPE no tiene una dirección IP WAN registrada, se evita continuar
      if (ipcpe == null || ipcpe == '') return

      // Filtrado de la puerta de enlace compatible
      var puertaEnlace = self.puertas_enlace.find(function(pe) {
        let tmp = pe.split('/')

        // Si no tiene CIDR, evita continuar
        if (tmp[1] == undefined) return false
        let cidr = tmp[1]

        return (
          ipaddr.IPv4.networkAddressFromCIDR(pe).toString() ==
          ipaddr.IPv4.networkAddressFromCIDR(ipcpe + '/' + cidr).toString()
        )
      })

      let addr
      try {
        let tmp1 = puertaEnlace.split('/')
        addr = ipaddr.IPv4.parseCIDR(puertaEnlace)
        self.configuracionCpe.puerta_enlace = addr[0].toString()
        self.configuracionCpe.mascara_subred = ipaddr.IPv4.subnetMaskFromPrefixLength(
          addr[1]
        )
        self.configuracionCpe.cidr = tmp1[1]
      } catch (error) {
        if (self.cpe.id_router != null) {
          self.configuracionCpe.puerta_enlace = '- - Ninguna - -'
          self.configuracionCpe.mascara_subred = '- - Ninguna - -'
          iu.msg.warning(`El router seleccionado no cuenta con una puerta de enlace compatible con la dirección IP del CPE. 
                        Actualiza la dirección IP WAN del CPE o selecciona otro router de administración`)
        }
      }

      // Actualización de los DNS sugeridos
      // Se obtiene el router seleccionado
      var router = self.routers.find(function(rt) {
        return rt.id == self.cpe.id_router
      })

      if (router == null) {
        self.configuracionCpe.dns1 = 'No hay DNS sugerido'
        self.configuracionCpe.dns2 = 'No hay DNS sugerido'
        self.configuracionCpe.cidr = null
      } else {
        self.configuracionCpe.dns1 = router.dns1_sugerido
        self.configuracionCpe.dns2 = router.dns2_sugerido
      }
    },

    cargarCpe: function(callback) {
      var self = this
      var idCpe = this.$route.params.id

      CpeSrv.cpeJSON(idCpe).then(response => {
          let cpe = response.data
          Object.assign(self.cpe, cpe)

          if (callback) callback()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo cargar el CPE'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
    },

    cargarEstados: function(callback) {
      var self = this
      EstadoCpesSrv.estadosJSON().then(response => {
        self.estados = response.data

        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los estados'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMarcas: function(callback) {
      var self = this

      MarcaCpesSrv.marcasJSON().then(response => {
        self.marcas = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelos: function(callback) {
      var self = this
      let params = { id_marca: self.cpe.id_marca }

      ModeloCpesSrv.modelosJSON(params).then(response => {
        self.modelos = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los modelos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPerfilVelocidadContrato: function() {
      var self = this

      PerfilVelocidadCpesSrv.perfilesJSON().then(response => {
        self.perfilEnContrato = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los perfiles de velocidad'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPerfilesVelocidad: function(callback) {
      var self = this

      PerfilVelocidadCpesSrv.perfilesJSON().then(response => {
        self.perfiles = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los perfiles de velocidad'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPerfilSeleccionado: function() {
      var self = this

      // Si el CPE no tiene un perfil de velocidad asignado, se evita continuar
      if (self.cpe.id_perfil_velocidad == null) return

      var perfil = self.perfiles.find(function(prf) {
        return prf.id == self.cpe.id_perfil_velocidad
      })

      // Si no se econtró el perfil, es probable que esté eliminado,
      // por lo cual se procede a cargarlo individualmente
      if (perfil == undefined) {
        PerfilVelocidadCpesSrv.perfilJSON(self.cpe.id_perfil_velocidad).then(response => {
          self.perfiles.push(response.data)
          self.perfilSeleccionado = self.perfiles.find(function(prf) {
            return prf.id == self.cpe.id_perfil_velocidad
          })
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'Ocirrió un error a la hora de cargar el perfil seleccionado'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
      // Si se encontró, se asigna el perfil encontrado al perfil seleccionado
      else {
        this.perfilSeleccionado = perfil
      }
    },

    cargarPuertasEnlace: function(callback) {
      var self = this

      // Si el CPE no tiene definido un id de router, evita continuar
      if (self.cpe.id_router == null) {
        self.puertas_enlace = []
        if (callback) callback()
        return
      }

      RouterSrv.puertasEnlaceJSON(self.cpe.id_router).then(response => {
        self.puertas_enlace = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las puertas de enlace del router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarRouters: function(callback) {
      var self = this

      RouterSrv.routersJSON().then(response => {
        self.routers = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarServiciosPPPoE: function() {
      var self = this

      // Si el CPE no tiene un router definido, evita continuar
      if (self.cpe.id_router == null) return

      RouterSrv.serviciosPppoeJSON(self.cpe.id_router).then(response => {
        self.serviciosPPPoE = response.data

        if (self.cpe.pppoe == true && self.serviciosPPPoE.length == 0) {
          iu.msg.warning(
            'No hay un servicio PPPoE disponible en el router para el CPE. ' +
              'Selecciona otro router o registra una LAN con el servicio PPPoE activado'
          )
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los servicios PPPoE'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarSitios: function() {
      var self = this

      let params = { dato: self.dato }

      SitioSrv.sitiosJSON(params).then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    generarClavePPPoE: function() {
      this.cpe.clave_pppoe = Math.floor(Math.random() * 9999999999 + 1000000000)
      this.cpe.clave_pppoe = this.cpe.clave_pppoe.toString(16)
    },

    generarUsuarioPPPoE: function() {
      var self = this

      SistemaSrv.fecha({ formato: 'YmdHis' }).then(response => {
        self.cpe.usuario_pppoe = 'cpe' + response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    guardar: function() {
      var self = this
      var cpe = Object.assign({}, self.cpe)

      if (cpe.pppoe == true) {
        if (cpe.usuario_pppoe == null || cpe.usuario_pppoe == '') {
          iu.msg.warning('Es necesario un usuario para la credencial PPPoE')
          self.$refs.usuario_pppoe.focus()
          return
        }
        if (cpe.clave_pppoe == null || cpe.clave_pppoe == '') {
          iu.msg.warning('Es necesario una clave para la credencial PPPoE')
          self.$refs.clave_pppoe.focus()
          return
        }
      }

      if (!self.datosCorrectos()) return

      CpeSrv.guardar(self.cpe).then(response => {
        iu.msg.success('Nuevo CPE guardado')

        // Limpieza del formulario
        self.limpiar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error.response.data)
      })
    },

    limpiar: function() {
      this.cpe = Object.assign({}, this.cpeInicial)
    },

    refrescarProveedoresInternet: function() {
      var self = this

      // Si el CPE no está asignado a un router, limpia la lista y evita continuar
      if (self.cpe.id_router == null) {
        self.isps = []
        self.cpe.id_isp = null
        return
      }

      RouterSrv.ispsJSON(this.cpe.id_router).then(response => {
        self.isps = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los ISPs a la hora de refrescar los proveedores de internet'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    resinc() {
      CpeSrv.resinc(this.cpe.id).then(response => {
        iu.msg.success('Instrucción registrada. En breve la configuración del CPE será re-sincronizada en el router')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo re-sincronizar la configuración'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    datosCorrectos: function() {
      var self = this,
        cpe = this.cpe,
        regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
      

      if (cpe.nombre == '') {
        iu.msg.warning('Es necesario un nombre')
        self.$refs.nombre.focus()
        return false
      }

      if (cpe.id_marca == null) {
        iu.msg.warning('Es necesario seleccionar una marca')
        self.$refs.id_marca.focus()
        return false
      }

      if (cpe.id_modelo == null) {
        iu.msg.warning('Es necesario seleccionar un modelo')
        self.$refs.id_modelo.focus()
        return false
      }

      if (cpe.pppoe == false && cpe.ip_wan == '') {
        iu.msg.warning('Es necesario registrar una IP WAN válida')
        self.$refs.ip_wan.focus()
        return false
      }

      if(cpe.pppoe == false && cpe.ip_wan.length > 15){
        iu.msg.warning('La direccion IP del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion IP')
        self.$refs.ip_wan.focus()
        return false
      }

      if(cpe.ip_lan != '' && cpe.ip_lan != null && cpe.ip_lan.length > 15){
        iu.msg.warning('La direccion IP del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion IP')
        self.$refs.ip_lan.focus()
        return false
      }

      if(cpe.mac_wan != '' && cpe.mac_wan != null){
        // if(!regex.test(cpe.mac_wan)){
        //   iu.msg.warning('La direccion MAC del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion MAC')
        //   self.$refs.mac_wan.focus()
        //   return false
        // }
        if(cpe.mac_wan.length > 17){
          iu.msg.warning('La direccion MAC del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion MAC')
          self.$refs.mac_wan.focus()
          return false
        }
      }

      if(cpe.mac_lan != '' && cpe.mac_lan != null){
        // if(!regex.test(cpe.mac_lan)){
        //   iu.msg.warning('La dirección MAC del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion MAC')
        //   self.$refs.mac_lan.focus()
        //   return false
        // }
        if(cpe.mac_lan.length > 17){
          iu.msg.warning('La dirección MAC del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion MAC')
          self.$refs.mac_lan.focus()
          return false
        }
      }

      try {
        if (
          cpe.ip_wan != '' &&
          cpe.ip_lan != '' &&
          self.configuracionCpe.cidr != null &&
          self.configuracionCpe.cidr != ''
        ) {
          var net1 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_wan + '/' + self.configuracionCpe.cidr
            ),
            net2 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_lan + '/' + self.configuracionCpe.cidr
            )

          if (net1.toString() == net2.toString()) {
            iu.msg.error(
              'El segmento de red de la dirección IP LAN debe ser diferente al segmento de red de la dirección IP WAN'
            )
            self.$refs.ip_lan.focus()
            return false
          }
        } else {
          var net1 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_wan + '/24'
            ),
            net2 = ipaddr.IPv4.networkAddressFromCIDR(self.cpe.ip_lan + '/24')

          if (net1.toString() == net2.toString()) {
            iu.msg.warning(
              'Es muy probable que la dirección IP WAN y LAN estén en el mismo segmento. Revisa que los datos sean correctos.'
            )
            self.$refs.ip_lan.focus()
          }
        }
      } catch (error) {}

      if (cpe.id_router != null) {
        if (cpe.id_isp == null) {
          iu.msg.warning('Es necesario seleccionar un proveedor de internet')
          self.$refs.id_isp.focus()
          return false
        }

        if (cpe.id_perfil_velocidad == null) {
          iu.msg.warning('Es necesario seleccionar un perfil de velocidad')
          self.$refs.id_perfil_velocidad.focus()
          return false
        }
      }

      return true
    },

    mostrarMdlThroughPut: function(idCpe) {
      var self = this

      self.$refs.mdlthroughput.mostrar(idCpe)
    }
  },
}
</script>

<style lang="scss" scoped>
.b-r {
  border-right: 2px solid #e7eaec;
}

.imgCpe {
  line-height: 110px;
}

.imgCpe img {
  vertical-align: middle;
  max-width: 100%;
  max-height: 135px;
}
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>